import React from "react"
import MainNav from "../components/mainnav"
import { Link } from "gatsby"

const AboutPage = () => (
  <div className="md:bg-white bg-white text-black pb-20">
    <div className="container max-w-full md:static sticky top-0 md:mb-20 mb-8 mx-auto text-center border-b-1 border-sand shadow-2xl">
      <MainNav />
    </div>
    <div className="container overflow-auto lg:flex mx-auto items-center justify-center border-8 border-white rounded-lg shadow-2xl">
      <img
        src="../../our-story-main.jpg"
        alt="Main"
        className="object-contain max-h-full lg:ml-10 mx-auto shadow-2xl border-0 border-white rounded-lg justify-center"
      ></img>
      <div className="lg:pr-10 ml-5">
        <p className="lg:text-left text-center md:text-5xl text-3xl font-serif">
          About This Website
        </p>
        <p className="lg:text-left text-center mt-0 text-left text-4xl lg:mb-0 mb-10">
          __
        </p>
        <p className="mb-5 text-lg">
          We both love working in IT and have always wanted to work on a project
          like this together. We've known that we'd like to build our own
          website ever since we started wedding planning. Alicia is an aspiring
          UX designer and I always like to dabble in code so this was a perfect
          opportunity for us to test ourselves! Below is a list of the technical
          libraries we used as well as links to their documentation.
        </p>
        <p className="mb-5 text-lg">
          The main tool we used is{" "}
          <Link
            to="https://www.gatsbyjs.com/"
            className="text-ocean hover:underline"
          >
            Gatsby
          </Link>{" "}
          which is an awesome static site generator built on top of{" "}
          <Link
            to="https://reactjs.org/"
            className="text-ocean hover:underline"
          >
            ReactJS
          </Link>
          . For all the styling, we used{" "}
          <Link
            to="https://tailwindcss.com/"
            className="text-ocean hover:underline"
          >
            Tailwind
          </Link>{" "}
          which is a super non-opinionated CSS framework that allows for highly
          customizable styling. Making layouts responsive with Tailwind was very
          easy! The site is hosted for free on{" "}
          <Link
            to="https://docs.gitlab.com/ee/user/project/pages/"
            className="text-ocean hover:underline"
          >
            GitLab Pages
          </Link>{" "}
          and we bought the awesome domain (if I do say so myself) from{" "}
          <Link
            to="https://www.godaddy.com/"
            className="text-ocean hover:underline"
          >
            GoDaddy
          </Link>
          . If you would like to build your own website using any of these tools
          and want to reach out about our experience please feel free, and as
          always, happy coding!
        </p>
      </div>
    </div>
  </div>
)

export default AboutPage
